<script>
export default {
  name: "TravelerInfoVue",
  props: {
    travelers: {},
    editable: {
      type: Boolean,
      default: false
    },
    isButtonHidden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    toRailHome: function() {
      this.$router.push({
        name: "rail-home"
      });
    },
    checkOut: function() {
      this.$emit("checkOut");
    }
  }
};
</script>

<template>
  <div>
    <b-card no-body>
      <div
        v-for="(item, index) in travelers"
        :key="'travelerInfo-' + index"
        class="mb-2 p-3 border-info border-top"
      >
        <h5 class="font-size-14">
          Traveler {{ parseInt(index) + 1 }} ({{ item.code }})
        </h5>
        <b-row>
          <b-col cols="4">
            <label>Passenger</label>
          </b-col>
          <b-col>
            {{ item.passenger }}
          </b-col>
          <b-col v-if="editable">
            <b-button size="sm" variant="danger">Edit</b-button>
          </b-col>
        </b-row>

        <b-row v-if="item.birthDate">
          <b-col cols="4"><label>Date of Birth</label></b-col>
          <b-col>{{ item.birthDate }}</b-col>
        </b-row>

        <b-row v-if="item.telephoneNumber">
          <b-col cols="4"><label>Telephone Number</label></b-col>
          <b-col>{{ item.telephoneNumber }}</b-col>
        </b-row>

        <b-row v-if="item.email">
          <b-col cols="4"><label>Email</label></b-col>
          <b-col>{{ item.email }}</b-col>
        </b-row>
      </div>
    </b-card>

    <div v-if="!isButtonHidden" class="my-3 d-flex justify-content-end">
      <!-- <b-button variant="info"
    @click="toRailHome"
    >Add other items</b-button> -->

      <b-button
        variant="success"
        @click="checkOut"
        v-if="!isButtonHidden"
        v-permission="{
          permission: 'agencyportal.rail.ticketing',
          effect: 'hidden'
        }"
        >Check Out</b-button
      >
    </div>
  </div>
</template>

<style scoped></style>
