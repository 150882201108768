<script>

    export default {
        props: {
            priceDetails: {},
            conditions: {},
            currencyCode: {},
            totalAmount: {},
            sellingCurrencyCode: {},
            sellingTotalAmount: {},   
        },
        data () {
            return {
            }
        }


    }
</script>

<template>

    <!-- <b-card
    class="border border-light"
    > -->

        <b-table-simple
        caption-bottom 
        borderless
        small
        text-variant="primary"
        class="bg-dark text-white"
        >
            <b-tbody>
				<b-tr>
					<b-td colspan="2"
					class="text-right"
					>
						Fare before commission
					</b-td>
				</b-tr>
                <b-tr v-for="item, index in priceDetails" :key="index">
                    <b-td>{{item.quantity + 'x'}}&nbsp;&nbsp;  {{item.code}}</b-td>
                    <b-td class="text-right">
                        <span v-if="item.sellingAmount == 0">Free</span> 
                        <span v-else>{{item.sellingCurrencyCode}}&nbsp;&nbsp; {{item.sellingAmount}}</span>
                    </b-td>
                </b-tr>
                
                <b-tr>
                    <b-td colspan="2"
                    class="text-right"
                    >
                        <!-- TOTAL &nbsp;&nbsp; <span class="text-danger"><b>{{sellingCurrencyCode}} &nbsp;{{sellingTotalAmount}}</b></span> -->
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>      

</template>
